import {
  Flex, HStack, Button, useDisclosure, Box, IconButton, Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Divider,
} from "@chakra-ui/react";
import React, { useState, useEffect } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { scroller } from 'react-scroll';
import { Link as LinkRouter, useLocation, useNavigate } from 'react-router-dom';
import { logNavigationClick, logCtaClick } from '../utils/analytics';
import DropllaLogo from "assets/logo/dropllaLogo";
import ToolsMenu from "./ToolsMenu";
import ToolsMenuMobile from "./ToolsMenuMobile";

const Header = () => {
  const [hasScrolled, setHasScrolled] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const navigate = useNavigate();
  const { hash, pathname } = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setHasScrolled(scrollTop > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const onPageLoad = () => {
      setIsPageLoaded(true);
    };

    if (document.readyState === 'complete') {
      setIsPageLoaded(true);
    } else {
      window.addEventListener('load', onPageLoad);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  const handleNavLinkEventLog = (label) => {
    logNavigationClick('Header', label);
  };

  const handleActionLinkEventLog = (label) => {
    logCtaClick('Header', label);
  };

  const handleNavigation = (sectionId) => {
    navigate(`/#${sectionId}`);
    scroller.scrollTo(sectionId, {
      smooth: true,
      duration: 800,
      offset: -50,
    });
  };

  useEffect(() => {
    if (hash && isPageLoaded) {
      const sectionId = hash.replace('#', '');
      scroller.scrollTo(sectionId, {
        smooth: true,
        duration: 800,
        offset: -50,
      });
    }
  }, [hash, pathname, isPageLoaded]);

  const redirectToBlog = () => {
    window.location.href = `${window.location.origin}/blog`;
  };

  return (
    <React.Fragment>
      <Box
        bg="rgba(255, 255, 255, 0.90)"
        w="full"
        px={{
          base: 2,
          md: 7,
        }}
        py={4}
        shadow={hasScrolled ? 'md' : 'sm'}
        position="sticky"
        top="0px"
        zIndex="999"
      >
        <Flex alignItems="center" justifyContent="space-between" mx="auto">
          <Box
            display={{
              base: 'inline-flex',
              md: 'none',
            }}
          >
            <IconButton
              display={{
                base: 'flex',
                md: 'none',
              }}
              aria-label="Open menu"
              fontSize="30px"
              _dark={{
                color: 'inherit',
              }}
              paddingTop={1}
              variant="ghost"
              color="droplla.500"
              icon={<AiOutlineMenu />}
              onClick={onOpen}
            />
          </Box>

          <Flex>
            <DropllaLogo
              w={{ base: '120px', lg: '170px' }}
              _hover={{ cursor: 'pointer' }}
              onClick={() => {
                handleNavLinkEventLog('Home');
                handleNavigation('home');
              }}
            />
          </Flex>

          <HStack display="flex" alignItems="center" spacing={1}>
            <HStack
              spacing={1}
              mr={1}
              color="droplla.500"
              display={{
                base: 'none',
                md: 'inline-flex',
              }}
            >
              <Button
                variant="ghost"
                color="gray.600"
                fontWeight="500"
                onClick={() => {
                  handleNavLinkEventLog('Features');
                  handleNavigation('features');
                }}
              >
                Features
              </Button>

              <ToolsMenu />

              <Button
                variant="ghost"
                color="gray.600"
                fontWeight="500"
                onClick={() => {
                  handleNavLinkEventLog('Sign in');
                  redirectToBlog();
                }}
              >
                Blog
              </Button>

              <LinkRouter to="/sign-in?a=t">
                <Button
                  variant="ghost"
                  color="gray.600"
                  fontWeight="500"
                  onClick={() => handleNavLinkEventLog('Affiliate Sign in')}
                >
                  Affiliate
                </Button>
              </LinkRouter>

              <Button
                variant="ghost"
                color="gray.600"
                fontWeight="500"
                onClick={() => {
                  handleNavLinkEventLog('FAQ');
                  handleNavigation('faq');
                }}
              >
                FAQ
              </Button>
              <LinkRouter to="/sign-in">
                <Button
                  variant="ghost"
                  color="gray.600"
                  fontWeight="500"
                  onClick={() => handleNavLinkEventLog('Sign in')}
                >
                  Sign in
                </Button>
              </LinkRouter>
            </HStack>

            <LinkRouter to="/sign-up">
              <Button
                variant="droplla"
                size="sm"
                fontSize="sm"
                fontWeight="bold"
                onClick={() => handleActionLinkEventLog('Sign-up Free')}
              >
                Start For Free
              </Button>
            </LinkRouter>
          </HStack>
        </Flex>
      </Box>


      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent zIndex="1001">
          <DrawerCloseButton />
          <DrawerBody>
            <Box mt={6} justifyContent="center" alignItems="center" display="flex">
              <DropllaLogo
                w={{ base: '150px' }}
                _hover={{ cursor: 'pointer' }}
                onClick={() => {
                  handleNavLinkEventLog('Home');
                  handleNavigation('home');
                  onClose();
                }}
              />
            </Box>
            <Button
              mt={8}
              w="full"
              variant="ghost"
              justifyContent="flex-start"
              borderRadius="md"
              fontSize="xl"
              onClick={() => {
                handleNavLinkEventLog('Features');
                handleNavigation('features');
                onClose();
              }}
            >
              Features
            </Button>

            <ToolsMenuMobile />

            <Button
              mt={4}
              w="full"
              variant="ghost"
              justifyContent="flex-start"
              borderRadius="md"
              fontSize="xl"
              onClick={() => {
                handleNavLinkEventLog('Blog');
                redirectToBlog();
              }}
            >
              Blog
            </Button>

            <LinkRouter to="/sign-in?a=t">
                <Button
              mt={4}
              w="full"
              variant="ghost"
              justifyContent="flex-start"
              borderRadius="md"
              fontSize="xl"
                  onClick={() => handleNavLinkEventLog('Affiliate Sign in')}
                >
                  Affiliate
                </Button>
              </LinkRouter>

            <Button
              mt={4}
              w="full"
              variant="ghost"
              justifyContent="flex-start"
              borderRadius="md"
              fontSize="xl"
              onClick={() => {
                handleNavLinkEventLog('FAQ');
                handleNavigation('faq');
                onClose();
              }}
            >
              FAQ
            </Button>
            <Divider mt={4} />
            <Flex alignItems="center" justifyContent="center">
              <LinkRouter to="/sign-in">
                <Button
                  mt={4}
                  w="full"
                  variant="ghost"
                  colorScheme="droplla"
                  justifyContent="flex-start"
                  borderRadius="md"
                  fontSize="xl"
                  onClick={() => handleNavLinkEventLog('Sign in')}
                >
                  Sign in
                </Button>
              </LinkRouter>
              <Text fontSize={18} mt={4} display="flex" alignItems="center" mr={1} ml={1}>or</Text>
              <LinkRouter to="/sign-up">
                <Button
                  mt={4}
                  w="full"
                  variant="ghost"
                  colorScheme="droplla"
                  justifyContent="flex-start"
                  borderRadius="md"
                  fontSize="xl"
                  onClick={() => handleNavLinkEventLog('Register')}
                >
                  Register
                </Button>
              </LinkRouter>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </React.Fragment>
  );
};

export default Header;
