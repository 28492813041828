import Cookies from 'js-cookie';

function useAuth() {

  const checkSignInStatus = (affiliate=false) => {
    const isAuthenticated = !!Cookies.get('client-auth');
    if (isAuthenticated) {
      redirect(affiliate);
    }
    return isAuthenticated;
  }

  const signIn = (value, expiryIso8601, affiliate=false) => {
    // let expiryDate;
    // try {
    //   expiryDate = new Date(expiryIso8601);
    // } catch (error) {
    //   const now = new Date();
    //   now.setDate(now.getDate() + 7);
    //   expiryDate = now;
    // }

    redirect(affiliate);
  };

  const redirect = (affiliate=false) => {
    if(affiliate) {
      redirectToAffiliatePortal();
    } else {
      redirectToDashboard();
    }
  }


  const redirectToDashboard = () => {
    window.location.href = `${window.location.origin}/dashboard/home`;
  };

  const redirectToAffiliatePortal = () => {
    window.location.href = `${window.location.origin}/affiliate/portal`;
  }
  

  return { checkSignInStatus, signIn };
}





export default useAuth;
