import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Button,
  Text,
  Link,
} from "@chakra-ui/react";
import { BiMath } from "react-icons/bi";
import React from "react";

function ToolsMenuMobile() {
  return (
    <Accordion allowToggle mt={4}>
      <AccordionItem border="none">
        <AccordionButton
          _hover={{ bg: "transparent" }}
              w="full"
              variant="ghost"
              justifyContent="flex-start"
              borderRadius="md"
              fontSize="xl"
              fontWeight="700"
        >
          <Box flex="1" textAlign="left" >
            Tools
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pt={6}>

          <Link href="/dropshipping-profit-margin-calculator" _hover={{ textDecoration: "none" }}>
            <Button
              variant="ghost"
              justifyContent="flex-start"
              fontWeight="700"
              color="gray.600"
              p={4}
              w="full"
              fontSize="lg"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                border="1px"
                borderColor="gray.300"
                bg="white"
                rounded="md"
                boxSize="8"
                mr="3"
              >
                <BiMath color="#3182ce" size="20px" />
              </Box>
              <Text color="gray.600" fontSize="md" mb={0}>
                Profit Calculator
              </Text>
            </Button>
          </Link>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

export default ToolsMenuMobile;
